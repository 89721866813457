import { defineStore } from 'pinia';
import { postAll } from '@/api/org/post';
import { roleAll } from '@/api/perms/role';
import { deptAll } from '@/api/org/department';
import { dictDataAll, dictTypeAll } from '@/api/setting/dict';
import cache from '@/utils/cache';
import { getToken } from '@/utils/auth';
import { DeviceBrandAll } from '@/api/device_brand';

export interface BasicDataState {
    [key: string]: Record<string, any>[] | Record<string, boolean>;
    systemAuthRole: Record<string, any>[];
    systemAuthDept: Record<string, any>[];
    systemAuthPost: Record<string, any>[];
    dictType: Record<string, any>[];
    dictData: Record<string, any>[];
    administrativeDivision: Record<string, any>[];
    brand: Record<string, any>[];
    loadingFlags: Record<string, boolean>;
}

const basicData = 'basicData';

const exclude_resetKey = ['loadingFlags', 'fetchData', 'resetState'];

const useBasicDataStore = defineStore({
    id: basicData,
    state: (): BasicDataState => ({
        systemAuthRole: [] as any[],
        systemAuthDept: [] as any[],
        systemAuthPost: [] as any[],
        dictType: [] as any[],
        dictData: [] as any[],
        administrativeDivision: [] as any[],
        brand: [] as any[],
        loadingFlags: {},
    }),
    actions: {
        async fetchData(key: keyof BasicDataState, fetchFunction: () => Promise<any[]>) {
            const cacheKey = `${basicData}_${key}`;
            if (!cache.get(cacheKey) && !this.loadingFlags[key] && getToken()) {
                this.loadingFlags[key] = true;//避免CanceledError错误，如果有相同的请求并发，会被取消，抛出一堆的取消错误
                try {
                    const data = await fetchFunction();
                    cache.set(cacheKey, data);
                } finally {
                    this.loadingFlags[key] = false;
                }
            }
            const cachedData = cache.get(cacheKey);
            if (this[key].length === 0 && cachedData?.length > 0) {
                this[key] = cachedData;
            }
        },
        resetState(key?: string) {
            if (key) {
                if (exclude_resetKey.includes(key)) {
                    return;
                }
                const cacheKey = `${basicData}_${key}`;
                cache.remove(cacheKey);
                return;
            }
            const keys = Object.keys(this).filter(k => exclude_resetKey.indexOf(k) === -1);
            keys.forEach(k => {
                const cacheKey = `${basicData}_${k}`;
                cache.remove(cacheKey);
            });
        },
    },
});

const fetchMap: Record<string, (store: ReturnType<typeof useBasicDataStore>) => Promise<void>> = {
    systemAuthRole: async (store) => await store.fetchData('systemAuthRole', roleAll),
    systemAuthDept: async (store) => await store.fetchData('systemAuthDept', deptAll),
    systemAuthPost: async (store) => await store.fetchData('systemAuthPost', postAll),
    dictType: async (store) => await store.fetchData('dictType', dictTypeAll),
    dictData: async (store) => await store.fetchData('dictData', dictDataAll),
    administrativeDivision: async (store) => await store.fetchData('administrativeDivision', administrativeDivisionAll),
    brand: async (store) => await store.fetchData('brand', DeviceBrandAll),
};

function createBasicDataProxyStore() {
    const store = useBasicDataStore();

    return new Proxy(store, {
        get(target, prop, receiver) {
            const propKey = prop as keyof typeof fetchMap;
            if (propKey in fetchMap && typeof target.fetchData === 'function') {
                fetchMap[propKey](target);
            } else if (propKey in fetchMap) {
                console.error(`fetchData is not a function on store for ${propKey}`);
            }
            return Reflect.get(target, prop, receiver);
        }
    });
}

//createBasicDataProxyStore不能用默认导出，会有异想不到的bug，只能用export{}方式导出
export { createBasicDataProxyStore };

