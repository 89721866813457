<template>
    <component :is="renderIcon" :size="size" :color="color" class="local-icon"></component>
</template>

<script lang="ts">
import { defineComponent, h, resolveComponent, computed } from 'vue'
import { ElIcon } from 'element-plus'
import { EL_ICON_PREFIX, LOCAL_ICON_PREFIX } from './index'
import svgIcon from './svg-icon.vue'

export default defineComponent({
    name: 'Icon',
    props: {
        name: {
            type: String,
            required: true
        },
        size: {
            type: [String, Number],
            default: '14px'
        },
        color: {
            type: String,
            default: 'inherit'
        }
    },
    setup(props) {
        const renderIcon = computed(() => {
            if (props.name.indexOf(EL_ICON_PREFIX) === 0) {
                const iconName = props.name.replace(EL_ICON_PREFIX, '')
                const IconComponent = resolveComponent(iconName)
                return h(
                    ElIcon,
                    {
                    size: props.size,
                    color: props.color
                    },
                    () => h(IconComponent)
                )
            } else if (props.name.indexOf(LOCAL_ICON_PREFIX) === 0) {
                return h(svgIcon, { ...props })
            } else {
                return null
            }
        })

        return {
            renderIcon
        }
    }
})
</script>
  