import { createApp } from 'vue'
import App from './App.vue'
import install from './install'
import './permission'
import './styles/index.scss'
import 'virtual:svg-icons-register'

const app = createApp(App)

// 全局错误处理器
app.config.errorHandler = (err, instance, info) => {
    if (err) {
        console.error(`Vue error in component: ${instance?.$options.name || 'anonymous component'}`);
        console.error(`Additional info: ${info}`);
        console.error(err);
    }
};

// 捕获全局的 JavaScript 错误
window.onerror = (message, source, lineno, colno, error) => {
    if (error)
        console.error(`Global error: ${message} at ${source}:${lineno}:${colno}`, error);
};

// 捕获未处理的 promise 异常
// window.onunhandledrejection = (event) => {
//     if (event)
//         console.error('Unhandled rejection:', event.reason);
// };

app.use(install)
app.mount('#app')
